<template>
  <v-combobox
    v-model="selectedFormato"
    ref="formatoBox"
    :items="formatos"
    :search-input.sync="buscaFormato"
    :disabled="loadingFormato"
    :rules="obrigatorio ? formRules : ''"
    :required="obrigatorio"
    :label="label"
    item-text="descricao"
    item-value="id"
    clearable
    return-object
    dense
    @keydown.enter="addFormato"
  >
    <!--Se nao tiver nenhu dado no componente -->
    <template v-slot:no-data>
      <v-list-item v-if="!buscaFormato">
        nenhum formato encontrado
      </v-list-item>
      <v-list-item v-else @click="addFormato" class="my-n2 py-1">
        <span class="caption">Clique para adicionar </span>
        <v-sheet
          label
          color="accent"
          rounded=""
          class="px-3 py-1 ml-2 text-body-2"
        >
          {{ buscaFormato }}
        </v-sheet>
      </v-list-item>
    </template>

    <template v-slot:item="{ index, item }">
      <v-text-field
        v-model="item.descricao"
        v-if="item.edit"
        @click.stop
        append-icon="mdi-content-save"
        @click:append="updateFormato(item)"
      ></v-text-field>
      <template v-else>
        {{ item.descricao }}
      </template>

      <v-spacer></v-spacer>
      <v-list-item-action>
        <v-btn icon small @click.stop.prevent="item.edit = !item.edit">
          <v-icon v-if="item.edit" small>mdi-close</v-icon>
          <v-icon v-else small>mdi-pencil</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
import {
  fetchPostagensFormatos,
  putPostagemFormato,
  postPostagemFormato,
} from "@/api/geral/midias_sociais_postagens_formatos.js";
import { inputRequired } from "@/helpers/utils.js";

export default {
  name: "FormatoField",

  props: {
    item_sync: {
      type: [Number, String],
    },
    label: {
      type: String,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      require: true,
    },
    formato: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      selectedFormato: null,
      item: null,
      formatos: [],
      buscaFormato: "",
      loadingFormato: false,
      formRules: [inputRequired],
    };
  },

  watch: {
    buscaFormato() {
      if (
        this.selectedFormato &&
        this.selectedFormato.descricao != this.buscaFormato
      ) {
        this.selectedFormato = null;
      }
    },
    selectedFormato() {
      if (this.selectedFormato) {
        this.parseValue();
      }
    },
  },

  methods: {
    parseValue() {
      this.$emit("update:item_sync", this.selectedFormato.id);
    },

    getFormatos() {
      this.loadingFormato = true;
      return fetchPostagensFormatos()
        .then((response) => {
          const formatos = response.map((item) => {
            item.edit = false;
            return item;
          });

          this.formatos = formatos;
          this.loadingFormato = false;
        })
        .catch(() => {
          this.loadingFormato = false;
        });
    },

    addFormato() {
      let formato_existe = this.formatos.find(
        (item) => item.descricao === this.buscaFormato
      );

      if (formato_existe) {
        this.$toast.error("Formato ja existe, selecione nas lista!");
        this.buscaFormato = null;
        return;
      }

      let formato = { status: 1 };
      formato.descricao = this.buscaFormato;

      postPostagemFormato(formato)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Formato criado com sucesso!");

            this.getFormatos().then(() => {
              this.selectedFormato = this.formatos.find(
                (i) => i.descricao === formato.descricao
              );
            });

            this.$refs.formatoBox.isFocused = false;
          }
        })
        .catch(() => {});
    },

    updateFormato(item) {
      let formato = { status: 1 };
      formato.id = item.id;
      formato.descricao = item.descricao;

      putPostagemFormato(formato.id, formato)
        .then((response) => {
          if (response.status === 201) {
            item.edit = false;
            this.$toast.success("Formato atualizado com sucesso!");
            this.selectedFormato = formato.descricao;
            this.getFormatos();
          }
        })
        .catch(() => {
          this.loadingFormato = false;
        });
    },
  },

  async mounted() {
    await this.getFormatos();

    if (this.edit && this.formato) {
      this.selectedFormato = { ...this.formato };
    }
  },
};
</script>

<style lang="scss"></style>
