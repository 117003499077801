import api from "../axios_service.js";

// Busca Todas postagens formatos
export async function fetchPostagensFormatos(filtros) {
  const response = await api.get(
    `/postagens-formatos${filtros ? filtros : ""}`
  );
  return response.data.result;
}

// Busca uma postagem formato
export async function fetchPostagemFormato(id) {
  const response = await api.get(`/postagens-formatos/${id}`);
  return response.data.result;
}

// Atualiza uma postagem formato
export function putPostagemFormato(id, formato) {
  let body = {};
  for (let key in formato) {
    body[key] = formato[key];
  }
  return api.put(`/postagens-formatos/${id}`, body);
}

// Cria uma nova postagem formato
export function postPostagemFormato(formato) {
  let body = {};
  for (let key in formato) {
    body[key] = formato[key];
  }
  return api.post("/postagens-formatos/add", body);
}
